<template>
  <div class="console">
    <div v-for="p in myPrices" v-bind:key="p.id">@New Price ... € {{ p }}</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Console",
  data() {
    return {
      patr: 1050,
      amount: 1
    };
  },
  methods: {
    ...mapActions(["initWebsocket"])
  },
  computed: {
    ...mapGetters(["myBtc", "myPrices"])
  },
  created() {
    this.initWebsocket();
  }
};
</script>

<style scoped></style>
